import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import ErrorsInput from "@/components/global/inputs/errors-input";
import { SelectInputProps } from "@/TS/Interfaces/global_interfaces";
function SelectInput({
  id,
  label,
  needed,
  required,
  name,
  value,
  className,
  classNameDivider,
  children,
  labelClassName,
  register,
  error,
  containerClassName,
  selectContainerClassName,
  neededClassName,
  ...rest
}: SelectInputProps) {
  const [changed, setChanged] = useState(false);
  useEffect(() => {
    setChanged(false);
  }, [error]);
  const handleChange = () => {
    setChanged(true);
  };
  return <div className={twMerge("w-full relative", containerClassName)} data-sentry-component="SelectInput" data-sentry-source-file="select-input.tsx">
      <label htmlFor="" className={twMerge(labelClassName)}>
        {label}{" "}
        {needed && <span className={twMerge("text-primary", neededClassName)}>*</span>}
      </label>
      <div className={twMerge(`flex border items-center h-[48px] rounded-md border-dark-grey justify-center relative ${error?.err && !changed ? "border-1 border-primary bg-danger3 text-primary" : ""}`, selectContainerClassName)}>
        <select name={name} id={id} defaultValue={value} {...register(name, {
        onChange: () => handleChange()
      } ?? undefined)}
      // required={required}
      className={twMerge("w-full autofill:bg-transparent!important placeholder:italic rounded-md  pl-4 pr-1", error?.err && !changed ? "bg-danger3" : "", className)} {...rest}>
          {children}
        </select>
      </div>
      <ErrorsInput changed={changed} error={error} data-sentry-element="ErrorsInput" data-sentry-source-file="select-input.tsx" />
    </div>;
}
export default SelectInput;