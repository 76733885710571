export const handleErrors = (errors, path) => {
  if (errors) {
    for (const error of errors) {
      if (error.propertyPath === path) {
        return { err: true, message: error.message };
      }
    }
    return { err: false };
  }
};
