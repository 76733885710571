import { twMerge } from "tailwind-merge";
interface VerticalCurveProps {
  className: string;
}
export default function VerticalCurve({
  className
}: VerticalCurveProps) {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.381 99.998" className={twMerge(`h-full fill-primary stroke-primary ${className}`)} data-sentry-element="svg" data-sentry-component="VerticalCurve" data-sentry-source-file="vertical-curve.tsx">
      <path id="Intersection_44" data-name="Intersection 44" d="M0,100l0-.015c.2-.68,1.96-6.9,3.686-15.716C4.761,78.8,5.614,73.387,6.226,68.18A158.9,158.9,0,0,0,7.378,49.774,156.6,156.6,0,0,0,6.3,32.1c-.579-5.036-1.387-10.272-2.4-15.562C2.082,7.078.115.289.032,0H7.38V100Z" transform="translate(0 -0.002)" data-sentry-element="path" data-sentry-source-file="vertical-curve.tsx" />
    </svg>;
}