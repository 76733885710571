"use client";

import React, { createContext, useState } from "react";
export const isBurgerActiveContext = createContext<any[]>(["", () => {}]);
function BurgerActiveContext({
  children
}) {
  const [isActive, setIsActive] = useState(false);
  return <isBurgerActiveContext.Provider value={[isActive, setIsActive]} data-sentry-element="unknown" data-sentry-component="BurgerActiveContext" data-sentry-source-file="is-burger-active.tsx">
      {children}
    </isBurgerActiveContext.Provider>;
}
export default BurgerActiveContext;