"use client";

import React, { createContext, useContext, useEffect, useState } from "react";
import { isBurgerActiveContext } from "@/context/is-burger-active";
import { MobileContextValue } from "@/TS/Interfaces/global_interfaces";
export const MobileContext = createContext<MobileContextValue>(false);
function MobileProvider({
  children
}) {
  const [isActive, setIsActive] = useContext(isBurgerActiveContext);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  useEffect(() => {
    if (!isPublic) {
      setIsActive(false);
    }
  }, [isActive, isPublic]);
  return <MobileContext.Provider value={{
    isPublic,
    setIsPublic
  }} data-sentry-element="unknown" data-sentry-component="MobileProvider" data-sentry-source-file="mobile-provider.tsx">
      {children}
    </MobileContext.Provider>;
}
export default MobileProvider;