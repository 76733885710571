"use client";

import Link from "next/link";
import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import Icon from "@/components/icon";
import { SocialLinkProps } from "@/TS/Interfaces/global_interfaces";
const SocialLink = forwardRef<HTMLAnchorElement | HTMLDivElement, SocialLinkProps>(({
  iconSize,
  href,
  type,
  className,
  ...rest
}, ref: React.Ref<HTMLAnchorElement>) => {
  let icon = "";
  let title = "";
  let target = "";
  switch (type) {
    case "facebook":
      icon = "facebook";
      title = "Facebook";
      target = "_blank";
      break;
    case "linkedin":
      icon = "linkedin";
      title = "LinkedIn";
      target = "_blank";
      break;
    case "instagram":
      icon = "instagram";
      title = "Instagram";
      target = "_blank";
      break;
    case "phone":
      icon = "phone";
      title = "Téléphone";
      break;
    case "email":
      icon = "mail";
      title = "Email";
      break;
  }
  if (type === "email") return <a href={"mailto:" + href} ref={ref} className={twMerge(`rounded-full flex justify-center items-center m-0.5 w-fit bg-secondary text-white ${className}`)}>
          <Icon icon={icon} size={iconSize ?? 35} {...rest} />
        </a>;
  if (type === "phone") return <Link ref={ref} className={twMerge(`rounded-full flex justify-center items-center m-0.5 w-fit bg-secondary text-white ${className}`)} href={`tel:${href ?? "/"}`}>
          <Icon icon={icon} size={iconSize ?? 35} {...rest} />
        </Link>;
  return <Link ref={ref} href={href ?? "/"} target={target} className={twMerge(`rounded-full flex justify-center items-center m-0.5 w-fit bg-secondary text-white ${className}`)} title={title}>
        <Icon icon={icon} size={iconSize ?? 35} {...rest} />
      </Link>;
});
export default React.memo(SocialLink);