"use client";

import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";
import { CoordsProps } from "@/TS/Interfaces/global_interfaces";
export interface DropDownValue {
  setDropData: Dispatch<SetStateAction<DropData>>;
  dropData: DropData;
}
export interface DropData {
  form: any;
  jsx: ReactNode;
  modalRef: React.RefObject<HTMLDivElement>;
  coords: CoordsProps;
  isOut: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsOut: Dispatch<SetStateAction<boolean>>;
}
export const dropdownModalContext = createContext<DropDownValue>();
function DropdownModalProvider({
  children
}) {
  const [dropData, setDropData] = useState<DropData>(null);
  return <dropdownModalContext.Provider value={{
    setDropData,
    dropData
  }} data-sentry-element="unknown" data-sentry-component="DropdownModalProvider" data-sentry-source-file="dropdown-modal-provider.tsx">
      {children}
    </dropdownModalContext.Provider>;
}
export default DropdownModalProvider;