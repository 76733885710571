"use client";

import React, { useContext, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { dropdownModalContext } from "@/app/(main)/(club)/mes-clubs/[id]/leads/context/dropdown-modal-provider";
import { calculateCoords } from "@/app/(main)/(club)/mes-clubs/[id]/leads/utils";
import { DropdownProps } from "@/TS/Interfaces/global_interfaces";
function Dropdown({
  children,
  className,
  dropdownCard,
  jsx,
  form,
  isLoading,
  setIsDropData,
  additionalX,
  additionalY,
  isDropData
}: DropdownProps) {
  const ref = useRef(null);
  const modalRef = useRef(null);
  const [coords, setCoords] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOut, setIsOut] = useState(null);
  const [isOutBottom, setIsOutBottom] = useState(null);
  const {
    setDropData,
    dropData
  } = useContext(dropdownModalContext);
  const props = {};
  useEffect(() => {
    if (!ref.current) return;
    calculateCoords(setCoords, ref, additionalX, additionalY);
    window.addEventListener("resize", () => calculateCoords(setCoords, ref));
    return () => {
      window.removeEventListener("resize", () => calculateCoords(setCoords, ref));
    };
  }, [ref.current]);
  const handlePositioning = () => {
    if (!modalRef.current) return;
    const modalWidth = modalRef.current.offsetWidth;
    const modalHeight = modalRef.current.offsetHeight;
    const modalLeft = coords.offsetLeft + coords.w - modalWidth;
    const modalTop = modalRef.current.getBoundingClientRect().top;
    const isOutOfBottomBounds = modalHeight + modalTop > window.innerHeight;
    setIsOutBottom(isOutOfBottomBounds);
    const isOutOfLeftBounds = modalLeft < 0;
    setIsOut(isOutOfLeftBounds);
  };
  useEffect(() => {
    if (modalRef.current) {
      handlePositioning();
    }
  }, [modalRef.current, coords, isOpen, isOutBottom, isOut]);
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });
  useEffect(() => {
    if (dropdownCard) return;
    setDropData({
      jsx,
      form,
      modalRef,
      coords,
      isOut,
      isOutBottom,
      setIsOut,
      setIsOpen,
      isOpen
    });
  }, [isOpen, isLoading, dropdownCard, isOutBottom, isOut]);
  return <>
      <div ref={ref} onClick={e => {
      e.stopPropagation();
      setIsOpen(prevIsOpen => {
        const newIsOpen = !prevIsOpen;
        if (!newIsOpen) {
          return newIsOpen;
        }
        setTimeout(() => {
          if (modalRef.current) {
            handlePositioning();
          }
        }, 10);
        return newIsOpen;
      });
      setIsDropData && setIsDropData(!isDropData);
    }} className={twMerge("h-fit w-fit", className)}>
        {childrenWithProps}
      </div>
    </>;
}
export default Dropdown;