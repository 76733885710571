"use client";

import { useState, useEffect } from "react";
import { getMyProfile } from "@/app/api/dynabuy-back/api-profile";
import { Person } from "@/TS/Interfaces/global_interfaces";
export default function useProfile() {
  const [profile, setProfile] = useState<Person>(null);
  const getProfile = async () => {
    const profile = await getMyProfile();
    setProfile(profile);
  };
  useEffect(() => {
    getProfile();
  }, []);
  return profile;
}