import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { twMerge } from "tailwind-merge";
function ComponentLoading({
  label = "Chargement",
  className
}: {
  label?: string;
  className?: string;
}) {
  return <div className={"flex items-center justify-center h-full w-full"} data-sentry-component="ComponentLoading" data-sentry-source-file="component-loading.tsx">
      <Box sx={{
      display: "flex",
      color: "#EE4540"
    }} data-sentry-element="Box" data-sentry-source-file="component-loading.tsx">
        <div className="flex items-center justify-center text-2xl gap-4">
          <p className={twMerge(className)}>{label} </p>
          <CircularProgress sx={{
          color: "#EE4540"
        }} data-sentry-element="CircularProgress" data-sentry-source-file="component-loading.tsx" />
        </div>
      </Box>
    </div>;
}
export default ComponentLoading;