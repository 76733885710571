"use client";

import Image from "next/image";
import Link from "next/link";
import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import VerticalCurve from "@/components/vertical-curve";
import { isBurgerActiveContext } from "@/context/is-burger-active";
import { CardLinkProps } from "@/TS/Interfaces/global_interfaces";
export default function CardLink({
  img,
  verticalCurveClass,
  href,
  imgAlt,
  imgClassName,
  target,
  title,
  className,
  contentClassName,
  children,
  icon
}: CardLinkProps) {
  // let imgWidth = imgWidth ?? '60%';

  const [isActive, setIsActive] = useContext(isBurgerActiveContext);
  return <div className={twMerge(`rounded h-44 text-center flex flex-col items-center overflow-hidden mb-2 ${className}`)} data-sentry-component="CardLink" data-sentry-source-file="card-link.tsx">
      <Link onClick={() => setIsActive(false)} href={href} className={"flex h-full group w-full max-w-96"} target={target} data-sentry-element="Link" data-sentry-source-file="card-link.tsx">
        <div className={twMerge(`w-[60%] rounded-l min-w-[60%] relative overflow-hidden ${imgClassName}`)}>
          {icon ? <div className={`h-full bg-rencontres-blue px-2`}>{icon}</div> : <>
              <div className={"relative w-[105%] h-full transition-all group-hover:translate-x-[-5%]"}>
                <Image src={img ?? "/img/club-default-cover.jpg"} alt={imgAlt ?? ""} className={"min-w-full w-full h-full aspect-[17/9] object-cover object-left"} width={152} height={121} />
              </div>
            </>}
          <div className={"absolute h-full top-0 right-0 w-fit"}>
            <VerticalCurve className={verticalCurveClass} data-sentry-element="VerticalCurve" data-sentry-source-file="card-link.tsx"></VerticalCurve>
          </div>
        </div>
        <div className={twMerge(`bg-primary rounded-r flex justify-center items-center underline decoration-1 underline-offset-2 text-white py-6 w-full px-2 font-light group-hover:font-normal text-sm ${contentClassName}`)}>
          {children} {title}
        </div>
      </Link>
    </div>;
}