"use client";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import useMobile from "@/hooks/use-mobile";
import { ProfilePictureProps } from "@/TS/Interfaces/global_interfaces";
export const CustomTooltip = styled(({
  className,
  ...props
}: TooltipProps) => <Tooltip arrow {...props} classes={{
  popper: className
}} />)(({
  theme
}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));
function ProfilePicture({
  mobileSize,
  lastName,
  firstName,
  className,
  size,
  src,
  withTooltip,
  ...rest
}: ProfilePictureProps) {
  const isMobile = useMobile();
  let defaultClasses = "rounded-full aspect-square";
  const [pictureSize, setPictureSize] = useState<Readonly<number>>(0);
  useEffect(() => {
    setPictureSize(mobileSize && isMobile ? mobileSize : size);
  }, [mobileSize, isMobile, size]);
  const img = <Image src={src} alt={`${firstName} ${lastName}`} width={pictureSize ?? 48} height={pictureSize ?? 48} style={{
    height: pictureSize ?? 48,
    width: pictureSize ?? 48,
    minWidth: pictureSize ?? 48
  }} {...rest} className={twMerge("object-cover cursor-pointer h-unset", defaultClasses, className)} />;
  if (src) {
    if (withTooltip) {
      return <CustomTooltip title={firstName + " " + lastName} arrow>
          {img}
        </CustomTooltip>;
    } else {
      return <>{img}</>;
    }
  } else {
    return <p {...rest} className={twMerge(`flex items-center cursor-pointer justify-center bg-secondary text-white text-xl md:text-2xl font-thin`, defaultClasses, className)} style={{
      height: pictureSize ?? 48 + "px",
      width: pictureSize ?? 48 + "px",
      minWidth: pictureSize ?? 48 + "px"
    }}>
        {firstName && lastName ? <>{firstName[0] + lastName[0]}</> : <>-</>}
      </p>;
  }
}
export default ProfilePicture;